import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ББЗЗ дэх голлох үйл явдлын календарь",
  "description": null,
  "author": "OTC help",
  "category": "events",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`ББЗЗ дэх голлох үйл явдлын календарь`}</h2>
    <ol>
      <li parentName="ol">{`Оролцогч үндсэн цэсний `}<strong parentName="li">{`[Calendar]`}</strong>{` хэсэгт үнэт цаасны хүүгийн төлөлт, арилжаа болон үнэт цаастай холбоотой голлох үйл явдлуудын хуваарийг харах боломжтой.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      